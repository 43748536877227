@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

.blocked-teetime {
    background-color: yellow;
  } 
.e-edit-dialog .e-gridform .form-group.col-md-6 {
    width: 250px;
}
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css'; 
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';

@tailwind base;
@tailwind components;
@tailwind utilities;